// import { ReactComponent as ToastCloseIcon } from '@common/icons/ToastClose.svg';
import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import whiteClose from '@images/whiteClose.svg';
import Close from '@images/whiteClose.svg';
import classnames from 'classnames';
import React from 'react';

import { isMobile } from '../../../../common/utils';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
  Details?: boolean;
  viewCart?: () => void;
  productImage?: string;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast, quickView, productImage, Details }) => {
  setTimeout(() => {
    if (toasts.length && !isMobile()) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);
  let Toastcss = '';
  let errorBg = '';
  let successBg = '';
  if (Details) {
    errorBg = 'bg-errorToast';
    successBg = 'bg-successBGToast ';
    Toastcss = 'text-14 text-white mx-4 md:mx-0 mt-16 font-normal shadow-successBGToast rounded-10';
  } else {
    errorBg = 'bg-errorToast';
    successBg = 'bg-successBGToast ';
    Toastcss =
      'text-white text-14 leading-20 my-16 mx-4 md:mx-auto font-normal shadow-successBGToast md:max-w-490 rounded-10';
  }
  return (
    <>
      {quickView ? (
        <div className={classnames('z-999 top-10 md:top-0 absolute w-full')}>
          {toasts.map((toast) => (
            <div key={toast.key} className="w-full min-w-full md:w-369 md:min-w-369 md:max-w-410 px-10 md:px-0 mx-auto">
              <div
                className={
                  toast.variant === 'bg-toast-error'
                    ? 'bg-errorToast md:w-435  md:min-w-435 md:max-w-444 text-white mb-20 md:mb-24 shadow-successBGToast rounded-10'
                    : 'bg-successBGToast md:w-435 md:min-w-435 md:max-w-444 text-white mb-20 md:mb-24 shadow-successBGToast rounded-10'
                }
              >
                <div
                  className={classnames(
                    'container mx-auto w-full font-semibold flex items-center p-20 text-16 leading-22 justify-between',
                  )}
                  font-customisation="para-text"
                >
                  <div dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                  <img
                    src={whiteClose}
                    alt="Close"
                    className="ml-16 cursor-pointer h-14 flex md:hidden"
                    onClick={() => removeToast(toast.key)}
                  />
                  <img
                    src={Close}
                    alt="Close"
                    className="ml-16 cursor-pointer h-14 hidden md:flex"
                    onClick={() => removeToast(toast.key)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          className={classnames('flex flex-wrap', {
            'md:justify-center z-30 container px-0 relative mx-auto w-full': Details,
            'justify-center absolute md:container md:px-0 w-full md:w-auto mx-auto md:top-135 z-99999': !Details,
          })}
        >
          <div className={classnames({ 'w-full md:w-473 z-30 md:absolute right-0': Details })}>
            <div className={classnames('w-full')}>
              {toasts.map((toast) => {
                return (
                  <div key={toast.key}>
                    <div className="w-full md:m-0">
                      <div
                        className={
                          toast.variant === 'bg-toast-error' ? ` ${errorBg} ${Toastcss}` : ` ${successBg} ${Toastcss}`
                        }
                      >
                        <div className="flex flex-row">
                          <div
                            className={classnames(
                              'container mx-auto w-full flex font-bold items-center text-16 justify-start',
                              {
                                'p-13': Details,
                                'p-20 sm:w-300': !Details,
                              },
                            )}
                          >
                            {Details ? (
                              <img
                                src={productImage}
                                alt="product"
                                className="flex mr-10 w-46 min-w-46 h-46 rounded-5 object-contain border"
                              />
                            ) : null}
                            <div
                              className={classnames('flex flex-col mr-6 ', {
                                '': Details,
                              })}
                            >
                              <div
                                className={classnames('font-semibold text-14 leading-22', {
                                  '': Details,
                                })}
                                font-customisation="para-text"
                                dangerouslySetInnerHTML={{ __html: toast.content }}
                              ></div>
                            </div>
                          </div>
                          <div
                            className={classnames(
                              'flex flex-row ml-6 py-2 p-20 mt-20 md:mt-0 self-start md:self-center',
                              {},
                            )}
                          >
                            <img
                              src={Close}
                              alt="Close"
                              className="cursor-pointer z-999 min-w-14 min-h-14 h-14 w-14"
                              onClick={() => {
                                removeToast(toast.key);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Toasts;
